'use client';

import { useRef } from 'react';
import Link from 'next/link';

interface HeroTextProps {
  roleWords: string[];
  clientWords: string[];
  isDesktop?: boolean;
}

export const HeroText = ({ 
  roleWords, 
  clientWords, 
  isDesktop = false,
}: HeroTextProps) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const renderText = () => (
    <div className="flex flex-col text-justify">
      <span className="text-[18px] self-start text-white/50 mb-4">Giovanni Cordova</span>
      <span className="flex items-center gap-3 font-medium">
        Italian 
        <span className="relative font-extralight italic bg-gradient-to-r from-white/80 via-white to-white/80 bg-[length:200%_100%] animate-slowShimmer bg-clip-text text-transparent">
          Audio Engineer
        </span>
      </span>
      <span className="font-medium">Helping <span className="relative font-extralight italic bg-gradient-to-r from-white/80 via-white to-white/80 bg-[length:200%_100%] animate-slowShimmer bg-clip-text text-transparent">
        Artists
      </span> Unlock</span>
      <span className="font-medium">World Class Sound,</span>
      <span className="font-medium">Globally.</span>
      <Link 
        href="https://open.spotify.com/playlist/5cFUYTfH0gHhYP8DsgptAU?si=d1f7d511b56e419a"
        target="_blank"
        rel="noopener noreferrer"
        className="text-[18px] self-start mt-4 flex items-center gap-2 group transition-colors duration-300"
      >
        <svg 
          viewBox="0 0 24 24" 
          className="w-5 h-5 text-[#1DB954] group-hover:text-[#1ed760] transition-colors duration-300"
          fill="currentColor"
        >
          <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
        </svg>
        <span className="text-white relative after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-white after:transition-transform after:duration-300 group-hover:after:scale-x-100">Have a Listen</span>
      </Link>
    </div>
  );

  if (isDesktop) {
    return (
      <div className="w-full max-w-2xl mx-auto flex items-center justify-center">
        <div 
          ref={textRef}
          className="text-[60px] tracking-wider text-white font-light"
        >
          {renderText()}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="text-[24px] tracking-wider text-white font-light">
        {renderText()}
      </div>
    </div>
  );
}; 
export interface GalleryImage {
  src: string;
  alt: string;
  span: string;
  priority?: boolean;
  className?: string;
}

export const galleryImages: GalleryImage[] = [
  {
    src: '/images/gallery/giovanni-cordova-studio-best-mixing-mastering-services-2025-16.png',
    alt: 'Studio Equipment Wide Shot',
    span: 'col-span-3 row-span-2',
    priority: true,
    className: 'z-10'
  },
  {
    src: '/images/gallery/giovanni-cordova-studio-mixing-analog-tape-mastering-2025-03.jpg',
    alt: 'Studio Equipment',
    span: 'col-span-1 row-span-2',
    className: 'z-20'
  },
  {
    src: '/images/gallery/giovanni-cordova-studio-mixing-mastering-neve-analog-equipment-london-uk-2025.jpg',
    alt: 'Studio Equipment',
    span: 'col-span-1 row-span-1',
    className: 'z-30'
  },
  {
    src: '/images/gallery/giovanni-cordova-studio-mixing-mastering-analog-ssl-desk-audio-restoration-2025-01.jpg',
    alt: 'Studio Equipment',
    span: 'col-span-1 row-span-1',
    className: 'z-20'
  },
  {
    src: '/images/gallery/giovanni-cordova-studio-mixing-mastering-tape-analog-recording-spotify-professional-milan-ssl-sound-good-2025-12.jpg',
    alt: 'Studio Equipment',
    span: 'col-span-2 row-span-1',
    className: 'z-10'
  }
];

export const heroText = {
  roleWords: ['Audio Engineer'],
  clientWords: ['Artists']
}; 
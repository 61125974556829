'use client';

import { heroText } from '@/constants/heroContent';
import { HeroText } from './hero/HeroText';
import { AnnouncementBanner } from './AnnouncementBanner';
import Footer from './Footer';
import Link from 'next/link';
import { memo, lazy, Suspense } from 'react';
import Image from 'next/image';

// Lazy load non-critical components
const ImageCredit = lazy(() => import('@/components/hero/ImageCredit'));

// Memoize the Hero component to prevent unnecessary re-renders
export default memo(function Hero() {
  return (
    <section className="relative bg-[#0a0a0a]">
      {/* Content Container with fade effects */}
      <div className="relative min-h-screen flex flex-col items-center">
        {/* Top fade effect */}
        <div className="fixed top-0 left-0 right-0 h-40 bg-gradient-to-b from-[#0a0a0a]/90 md:from-[#0a0a0a]/[0.675] to-transparent z-40" />

        {/* Hero Text - Desktop */}
        <div className="hidden md:block mt-[15vh] text-center px-8">
          <HeroText 
            roleWords={heroText.roleWords}
            clientWords={heroText.clientWords}
            isDesktop={true}
          />
        </div>

        {/* Hero Text - Mobile */}
        <div className="md:hidden px-4 mt-[15vh] text-center">
          <HeroText 
            roleWords={heroText.roleWords}
            clientWords={heroText.clientWords}
            isDesktop={false}
          />
        </div>

        {/* Hero Image Container - Optimized for LCP */}
        <div className="w-[90%] md:w-[95%] max-w-7xl mx-auto aspect-[16/9] relative mt-32 mb-16 group hero-section">
          {/* Optimized Image for LCP */}
          <Image
            src="/images/giovanni-cordova-studio-mixing-mastering-neve-console-london-uk-2025.jpg"
            alt="Professional mixing and mastering environment with Neve console in London - high-end studio setup for premium audio production"
            fill
            priority
            fetchPriority="high"
            sizes="(max-width: 640px) 100vw, (max-width: 1024px) 95vw, 1536px"
            className="object-cover object-center rounded-lg priority-image hero-image"
            quality={85}
            loading="eager"
          />
          
          {/* Image Credit - Lazy loaded, only appears on hover */}
          <Suspense fallback={null}>
            <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <div className="px-5 h-8 rounded-md bg-[#0A0A0A]/90 text-[12px] font-light flex items-center">
                <div className="w-4 h-4 rounded-full overflow-hidden relative mr-2" style={{ backgroundImage: "url('/images/optimized/UK-sm.webp')", backgroundSize: "cover" }}></div>
                <span className="text-white/50 font-light">
                  <span className="text-white">London</span>, Early <span className="text-white">2024</span>. Picture by{' '}
                  <Link href="https://www.instagram.com/mario_simanovsky/" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                    Mario Simanovsky
                  </Link>
                </span>
              </div>
            </div>
          </Suspense>
        </div>

        {/* Announcement Banner - Lazy load below the fold */}
        <div className="w-full -mx-[5%]">
          <Suspense fallback={<div className="h-16 bg-[#0A0A0A]/20"></div>}>
            <AnnouncementBanner />
          </Suspense>
        </div>

        {/* Bottom fade effect */}
        <div className="fixed bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-[#0a0a0a]/90 md:from-[#0a0a0a]/[0.675] to-transparent z-40" />
      </div>
    </section>
  );
}); 
'use client';

export const AnnouncementBanner = () => {
  return (
    <div className="w-screen bg-white overflow-hidden">
      <div className="animate-scrollText hover:animate-none whitespace-nowrap py-3 font-light transition-all duration-150">
        {/* Repeat the announcement multiple times to ensure seamless looping */}
        {[...Array(4)].map((_, i) => (
          <span key={i} className="inline-block text-[#0a0a0a] text-lg px-4">
            To celebrate the website launch, use the code <strong className="font-bold">2025LAUNCH10</strong> to have a <strong className="font-bold">10%</strong> discount on your next project! 
            <svg 
              viewBox="0 0 24 24" 
              className="w-4 h-4 inline-block ml-2 align-middle"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            >
              <circle cx="12" cy="12" r="9" />
              <path d="M9 13.5s1.5 1.5 3 1.5 3-1.5 3-1.5" />
              <line x1="9" y1="9" x2="9.01" y2="9" />
              <line x1="15" y1="9" x2="15.01" y2="9" />
            </svg>
          </span>
        ))}
      </div>
    </div>
  );
}; 
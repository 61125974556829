'use client';

import React, { useEffect, lazy, Suspense } from 'react';
import { useRouter } from 'next/navigation';
import Header from '@/components/Header';
import Hero from '@/components/Hero';
import { isMobileDevice, isMobileViewport } from '@/utils/deviceDetection';
// Remove direct import of Footer
// import Footer from '@/components/Footer';

// Lazy load non-critical components
const Footer = lazy(() => import('@/components/Footer'));

export default function HomePage() {
  const router = useRouter();

  // Check if mobile and redirect if needed
  useEffect(() => {
    if (isMobileDevice() || isMobileViewport()) {
      router.replace('/mobile');
    }
  }, [router]);

  return (
    <main className="min-h-screen relative bg-[#0A0A0A]">
      {/* Content */}
      <div className="relative z-20">
        {/* Header */}
        <div className="bg-[#0A0A0A]">
          <Header activeSection="home" />
        </div>

        {/* Main Content - Critical LCP */}
        <div>
          <Hero />
        </div>

        {/* Footer */}
        <div className="bg-[#0A0A0A] py-12">
          <Suspense fallback={<div className="h-20"></div>}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </main>
  );
} 